/* Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  font-family: 'Roboto', sans-serif;
}

/* Grid Layout */
.home-container {
  display: grid;
  grid-template-columns: 1fr 2.2fr 1.2fr;
  grid-template-rows: auto 1fr;
  gap: 12px; /* Reduced from 20px */
  padding: 12px; /* Reduced from 20px */
  max-width: 1200px;
  margin: 0 auto;
}

/* Navigation wrapper spans full width */
.nav-steps-wrapper {
  grid-column: 1 / -1;
  width: 100%;
  padding: 0; /* Reset any padding */
  margin-bottom: 0.5rem; /* Reduced from 1rem */
}

.nav-steps-wrapper .nav-steps-container {
  margin-top: 0.5rem; /* Reduced from 1rem */
}

/* Main Content styling */
.main-content {
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px; /* Reduced from 15px */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 580px;
  margin: 0 auto;
}

/* Post Creation Form */
.create-post-form {
  top: 20px;
  width: 100%;
  background-color: #fff;
  padding: 12px; /* Reduced from 16px */
  border-radius: 8px;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.1),
    0 6px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px; /* Reduced from 16px */
}

.create-post-textarea,
.create-post-input {
  width: 100%;
  padding: 8px; /* Reduced from 10px */
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  margin-bottom: 8px; /* Reduced from 10px */
  font-family: 'Roboto', sans-serif;
}

.hidden-input {
  display: none; /* Skjuler den originale filopplastingsknappen */
}

.file-name {
  margin-top: 10px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #555;
}

/* Feed Styles */
.feed {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.post-feed {
  perspective: 1200px;
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
}

.post {
  width: 100%;
  border: 1px solid #ddd;
  padding: 12px; /* Reduced from 16px */
  background-color: white;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.1),
    0 6px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px; /* Reduced from 16px */
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

.post-image {
  max-width: 100%;
  height: auto;
  max-height: 600px;
  border-radius: 8px;
  object-fit: cover;
  margin-top: 10px;
}

.post-header {
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
}

.post-user-image {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  border: #000000 solid 1px;
}

.post-description {
  font-size: 0.9rem;
  color: #313131;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  padding-top: 20px;
  justify-content: center;
}

.post-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-top: 1px solid #e4e6eb;
  border-bottom: 1px solid #e4e6eb;
  margin: 12px 0;
}

.post-user-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.post-username {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 2px;
}

.post-user-company {
  font-size: 0.85rem;
  color: #666;
}

/* Comment Styles */
.comment {
  width: 100%;
  background: #ffffff;
  padding: 4px;
  border-radius: 12px;
  border: #cccccc solid 1px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.comment-header {
  display: flex;
  align-items: center;
  gap: 7px;
  width: 100%;
  margin-left: 0;
}

.comment-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.comment-user-image {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  border: #000 solid 1px;
  object-fit: cover;
  margin-right: 10px;
}

.comment-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 0;
}

.comment-user-name {
  font-weight: bold;
  font-size: 0.9rem;
  color: #333;
  margin: 0;
}

.comment-text {
  font-size: 0.85rem;
  color: #555;
  margin: 0;
}

.comment-time {
  font-size: 0.75rem;
  color: #777;
  margin-top: 4px;
}

.comment-actions {
  display: flex;
  align-items: center;
}

.comment-box {
  display: flex;
  margin-top: 15px;
  align-items: center;
  gap: 7px;
}

.comment-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  height: 35px;
}

/* Button Styles */
.create-post-button {
  background-color: #558392;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  height: 35px;
}

.create-post-button:hover {
  background-color: orange;
}

.custom-upload-button {
  background-color: #558392;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 0.8rem;
  cursor: pointer;
  margin-bottom: 10px;
  height: 35px;
}

.custom-upload-button:hover {
  background-color: orange;
  color: black;
}

.comment-button,
.delete-button,
.delete-button-comment {
  background-color: #f0f2f5;
  border: none;
  color: #65676b;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 50px;
  height: 37px;
  gap: 5px;
  padding: 8px 12px;
  border-radius: 20px;
}

.comment-button {
  margin-left: auto;
  margin-right: 10px;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.delete-button-comment {
  margin-left: auto;
}

.comment-button:hover,
.delete-button:hover,
.delete-button-comment:hover {
  color: #5c5d5f;
  background-color: #e4e6e9;
}

.comment-button:disabled,
.delete-button:disabled,
.delete-button-comment:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.like-button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  border: none;
  border-radius: 20px;
  background-color: #f0f2f5;
  color: #65676b;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.like-button:hover {
  background-color: #e4e6e9;
}

.like-button.liked {
  color: #2078f4;
  background-color: #e7f3ff;
}

.like-button.liked:hover {
  background-color: #dbe7f2;
}

.like-button svg {
  font-size: 18px;
}

.like-button span {
  margin-left: 4px;
}

.go-to-gantt-btn,
.edit-profile-btn,
.go-to-company-btn,
.go-to-projects-btn,
.my-projects-btn {
  background-color: #558392;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.go-to-gantt-btn,
.edit-profile-btn,
.go-to-company-btn,
.go-to-projects-btn {
  width: 100%;
  height: 32px;
  font-size: 0.8rem;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition:
    transform 0.2s ease,
    background-color 0.3s ease;
}

.my-projects-btn {
  font-size: 0.8rem;
  padding: 5px 5px;
  width: 120px;
}

.go-to-company-btn:hover,
.my-projects-btn:hover {
  background-color: orange;
}

.follow-button,
.follow-btn {
  min-width: 90px;
  height: 32px;
  padding: 0 12px;
  background: #558392;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.follow-button:hover,
.follow-btn:hover {
  background-color: orange;
}

.follow-button.following {
  background-color: #e0e0e0;
  color: #333;
}

.following-section .follow-button {
  min-width: 60px !important;
  height: 26px !important;
  font-size: 0.7rem !important;
  padding: 0 8px !important;
}

/* Error Message */
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
}

/* Sidebar */
.sidebar {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  align-content: stretch;
  justify-content: start;
  gap: 2px;
  background: #ffffff;
  padding: 10px;
  border-radius: 20px;
}

/* Profile Card Styles */
.profile-card,
.card {
  background: #fff;
  padding: 10px; /* Reduced from 15px */
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px; /* Reduced from 8px */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: fadeIn 1.2s ease forwards;
  transition:
    transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1),
    box-shadow 0.3s ease;
}

.profile-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.profile-card-image,
.profile-image {
  width: 50px; /* Reduced from 60px */
  height: 50px; /* Reduced from 60px */
  border-radius: 5px;
  object-fit: cover;
  border: #000000 solid 1.5px;
  transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.profile-card:hover .profile-card-image {
  transform: scale(1.05);
}

.profile-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.profile-details p {
  position: relative;
  overflow: hidden;
}

.profile-details p::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: currentColor;
  opacity: 0.5;
  transition: width 0.3s ease;
}

.profile-card:hover .profile-details p::after {
  width: 100%;
}

.profile-card-email {
  font-size: 0.75rem;
  color: #666;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.profile-card-name {
  font-size: 0.85rem;
  font-weight: bold;
  color: #333;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.profile-card-company {
  font-size: 0.7rem;
  font-weight: bold;
  color: #558392;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.profile-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 5px;
}

.profile-actions button {
  position: relative;
  overflow: hidden;
  transition:
    transform 0.2s ease,
    background-color 0.3s ease;
}

.profile-actions button:active {
  transform: scale(0.95);
}

.profile-actions button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.3) 10%,
    transparent 10.01%
  );
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition:
    transform 0.4s,
    opacity 0.8s;
}

.profile-actions button:active::after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

/* Sidebar Profile Card */
.sidebar-profile-card {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar-profile-card .profile-card-image {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.sidebar-profile-card .profile-details {
  width: 100%;
  text-align: center;
}

/* Logo Styles */
.plancoo-logo {
  display: flex;
  align-items: center;
  color: #558392;
  font-size: 3rem;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -2.25px;
  position: relative;
  opacity: 0;
  animation: fadeIn 1.2s ease forwards;
  font-family: 'Roboto', sans-serif;
}

.plancoo-logo-detail {
  display: flex;
  align-items: center;
  color: black;
  font-size: 25px;
  font-weight: 300;
  line-height: 72px;
  letter-spacing: -2.25px;
  position: relative;
  opacity: 0;
  animation: fadeIn 1.2s ease forwards;
  font-family: 'Roboto', sans-serif;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: white;
  z-index: 2;
}

.modal-content {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  gap: 10px;
}

.modal-content form label {
  display: block;
  margin-bottom: 1rem;
  color: white;
}

.modal-content input,
.modal-content textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.modal-content button {
  padding: 10px;
  background-color: orange;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  margin-top: 15px;
}

.modal-content button:hover {
  background-color: #607d8b;
}

body.modal-active {
  overflow: hidden;
}

/* Search Styles */
.search-input-container {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.search-input-container input {
  width: 100%;
  padding: 10px 15px;
  padding-right: 80px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  font-size: 0.9rem;
  text-overflow: ellipsis;
}

.search-input-container input::placeholder {
  opacity: 1;
  color: #757575;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-input-container button {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  min-width: 60px;
  height: 30px;
  border-radius: 6px;
  background: #558392;
  color: white;
  border: none;
  font-weight: 500;
  transition: all 0.2s ease;
  z-index: 2;
}

.search-results {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  min-height: 50px;
  position: relative;
}

/* Standardized Card Styling - Updated Layout */
.search-card,
.following-card,
.followers-card,
.profile-card-container {
  width: 100%;
  max-width: 350px;
  min-height: 50px; /* Reduced height */
  display: flex;
  align-items: center;
  justify-content: space-between; /* Spreads content across the card */
  padding: 6px 10px; /* Smaller padding */
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.4rem;
  gap: 8px;
}

/* Profile image styling */
.search-profile-image,
.following-profile-image,
.followers-profile-image {
  width: 36px; /* Smaller */
  height: 36px;
  min-width: 36px;
  border-radius: 4px;
  border: 1px solid #000;
  object-fit: cover;
}

/* Text content styling */
.search-details,
.following-details,
.followers-details {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 1px; /* Tighter spacing */
  padding: 0;
  align-items: flex-start; /* Left-align text */
  justify-content: center;
}

/* Keep text elements the same */
.search-name,
.following-name,
.followers-name {
  font-size: 0.85rem;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-company,
.following-company,
.followers-company {
  font-size: 0.75rem;
  color: #558392;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-email,
.following-email,
.followers-email {
  font-size: 0.7rem;
  color: #666;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Button styling - now positioned on the right */
.follow-button,
.follow-btn {
  min-width: 70px;
  height: 28px; /* Smaller height */
  padding: 0 8px;
  background: #558392;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.75rem; /* Smaller font */
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto; /* Push to the right */
}

/* Make follow buttons consistent across all sections */
.following-section .follow-button,
.followers-section .follow-button,
.search-results .follow-button {
  min-width: 60px !important;
  height: 24px !important; /* Even smaller */
  font-size: 0.7rem !important;
  padding: 0 6px !important;
}

/* Following/Followers Section */
.following-section,
.followers-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  margin-bottom: 20px;
  gap: 10px;
  position: relative;
}

.following-section h3,
.followers-section h2 {
  font-size: 0.9rem;
  color: #666;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
}

/* Animation for profile cards */
.following-section .profile-card-container {
  transform: translateX(-20px);
  opacity: 0;
  animation: slideInRight 0.3s forwards;
}

.following-section .profile-card-container:nth-child(2) {
  animation-delay: 0.05s;
}

.following-section .profile-card-container:nth-child(3) {
  animation-delay: 0.1s;
}

.following-section .profile-card-container:nth-child(4) {
  animation-delay: 0.15s;
}

.following-section .profile-card-container:nth-child(5) {
  animation-delay: 0.2s;
}

.following-section .profile-card-container:nth-child(6) {
  animation-delay: 0.25s;
}

.following-section .profile-card-container:hover {
  transform: translateY(-2px) scale(1.01);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  background: linear-gradient(to right, #ffffff, #f0f7fa);
}

.following-section .profile-card-container:hover .profile-card-image {
  transform: scale(1.08);
}

.following-section:empty::after {
  content: 'No results found';
  display: block;
  text-align: center;
  color: #666;
  font-size: 0.9rem;
  padding: 20px 0;
}

/* Projects */
.projects {
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  align-content: stretch;
  justify-content: start;
  gap: 5px;
  padding: 5px;
}

/* Toast Warning Buttons */
.toast-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.toast-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s ease;
}

.toast-buttons button:first-child {
  background-color: #007078;
  color: white;
}

.toast-buttons button:last-child {
  background-color: #6c757d;
  color: white;
}

.toast-buttons button:hover {
  opacity: 0.9;
}

/* Animation Keyframes */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes slideInRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Media Queries */
@media (max-width: 1024px) {
  .home-container {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto 1fr;
  }

  .nav-steps-wrapper {
    grid-column: 1 / -1;
    grid-row: 1;
  }

  .sidebar {
    grid-column: 1;
    grid-row: 2;
  }

  .main-content {
    grid-column: 2;
    grid-row: 2 / span 2;
  }

  .projects {
    grid-column: 1;
    grid-row: 3;
  }

  .main-content {
    max-width: 520px;
  }

  .post-feed {
    max-width: 490px;
  }
}

@media (max-width: 768px) {
  .home-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    padding: 10px;
  }

  .nav-steps-wrapper {
    grid-column: 1;
    grid-row: 1;
  }

  .sidebar {
    grid-column: 1;
    grid-row: 2;
  }

  .main-content {
    grid-column: 1;
    grid-row: 3;
  }

  .projects {
    grid-column: 1;
    grid-row: 4;
  }

  .main-content {
    max-width: 100%;
  }

  .post-feed {
    max-width: 100%;
  }

  .post {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .post,
  .comment,
  .feed {
    padding: 5px;
  }
}

/* Remove the duplicate code for follow buttons - keep only one version */

/* Fix the card layout with more specific rules */
.search-card,
.following-card,
.followers-card,
.profile-card-container,
.following-section .profile-card-container,
.followers-section .profile-card-container,
.search-results .search-card,
.search-results-container .profile-card {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  min-height: 50px !important;
  padding: 6px 10px !important;
  gap: 8px !important;
  width: 100% !important;
  max-width: 350px !important;
  background: white !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 0.4rem !important;
}

/* Make profile images consistently sized */
.search-profile-image,
.following-profile-image,
.followers-profile-image,
.following-section .profile-card-image,
.followers-section .profile-card-image,
.search-results .search-profile-image,
.search-results-container .profile-card-image {
  width: 36px !important;
  height: 36px !important;
  min-width: 36px !important;
  border-radius: 4px !important;
  margin-right: 0 !important;
  border: 1px solid #000 !important;
  object-fit: cover !important;
}

/* Left-align the text and keep it in the middle */
.search-details,
.following-details,
.followers-details,
.following-section .profile-details,
.followers-section .profile-details,
.search-results .search-details,
.search-results-container .profile-details {
  flex: 1 !important;
  margin-left: 8px !important;
  align-items: flex-start !important;
  justify-content: center !important;
  text-align: left !important;
}

/* Keep buttons small and on the right */
.follow-button,
.follow-btn,
.following-section .follow-button,
.followers-section .follow-button,
.search-results .follow-button,
.search-results-container .follow-button {
  min-width: 60px !important;
  height: 26px !important;
  font-size: 0.7rem !important;
  padding: 0 8px !important;
  margin-left: auto !important;
  background: #558392 !important;
  color: white !important;
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.follow-button:hover,
.follow-btn:hover,
.search-results-container .follow-button:hover {
  background-color: orange !important;
}

/* Search Results Container Styling */
.search-results-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}
