.navbar-logo-container {
  position: relative;
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-logo-text {
  font-size: 1.5rem;
  font-weight: 700;
  color: #558392;
  font-family: 'Poppins', sans-serif;
  position: relative;
  z-index: 2;
  text-shadow: 0 0 8px rgba(255, 255, 255, 1);
}

/* Raindrops container */
.navbar-raindrops {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Individual raindrop */
.navbar-raindrop {
  position: absolute;
  background-color: rgba(85, 131, 146, 0.6);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  opacity: 0;
  transform: scale(0);
  animation: navbarFalling 4s ease-in infinite;
}

/* Different animation delays for each drop */
.navbar-raindrop:nth-child(1) {
  animation-delay: 0s;
  left: 15%;
}
.navbar-raindrop:nth-child(2) {
  animation-delay: 0.6s;
  left: 35%;
}
.navbar-raindrop:nth-child(3) {
  animation-delay: 1.2s;
  left: 55%;
}
.navbar-raindrop:nth-child(4) {
  animation-delay: 1.8s;
  left: 75%;
}
.navbar-raindrop:nth-child(5) {
  animation-delay: 2.4s;
  left: 90%;
}

/* Different sizes for each drop */
.navbar-raindrop:nth-child(2n) {
  width: 8px;
  height: 8px;
}
.navbar-raindrop:nth-child(2n + 1) {
  width: 12px;
  height: 12px;
}

/* Animation for falling drops */
@keyframes navbarFalling {
  0% {
    top: -15px;
    opacity: 0;
    transform: scale(0);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 0.7;
  }
  100% {
    top: 120%;
    opacity: 0;
    transform: scale(0.5);
  }
}
