/* Company Profile styling med optimaliserte skriftstørrelser */
:root {
  --primary-color: #558392;
  --primary-light: #88a7b3;
  --primary-dark: #406b77;
  --text-dark: #333;
  --text-medium: #666;
  --text-light: #999;
  --border-light: #e0e0e0;
  --bg-light: #f8f9fa;
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.08);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
  --radius: 8px;

  /* Standardiserte font-størrelser */
  --font-xs: 0.75rem; /* 12px */
  --font-sm: 0.875rem; /* 14px */
  --font-base: 1rem; /* 16px */
  --font-md: 1.125rem; /* 18px */
  --font-lg: 1.25rem; /* 20px */
  --font-xl: 1.5rem; /* 24px */
  --font-2xl: 1.875rem; /* 30px */
}

/* Main profile container */
.company-profile {
  max-width: 1200px;
  margin: 0 auto; /* Remove top margin */
  padding: 0 1rem; /* Keep horizontal padding */
  /* No margin-top anymore */
}

/* Add spacing for NavSteps in company profile */
.company-layout .nav-steps-container {
  margin-bottom: 1.5rem;
  border-radius: 0; /* Make it flush with the top of the page */
  box-shadow: none; /* Remove shadow for seamless integration with header */
  width: 100%;
}

/* Mobile exceptions for better visual separation */
@media (max-width: 768px) {
  .company-layout .nav-steps-container {
    margin: 0 0 1rem 0; /* Fix margins for mobile */
    border-radius: var(--radius);
  }
}

/* Company header section with logo and details */
.company-header-section {
  background-color: white;
  border-radius: var(--radius);
  box-shadow: var(--shadow-md);
  margin-bottom: 1.5rem; /* Reduced from 2rem */
  overflow: hidden;
  /* Explicitly remove any border styling */
  border: none !important;
}

.company-header-container {
  display: grid;
  grid-template-columns: 180px 1fr; /* Redusert fra 200px */
  grid-gap: 1rem; /* Reduced from 2rem */
  padding: 1rem; /* Reduced from 1.5rem */
}

/* Add wrapper for logo and edit button */
.company-logo-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

/* Logo styling */
.company-logo-container {
  position: relative;
  width: 140px; /* Reduced from 180px */
  height: 140px; /* Reduced from 180px */
  background-color: var(--bg-light);
  border-radius: var(--radius);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-light);
}

/* New button style positioned below the logo */
.btn-edit-company-below {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  background-color: transparent;
  border: 1px solid var(--border-light);
  border-radius: var(--radius);
  color: var(--text-medium);
  font-size: var(--font-xs);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.btn-edit-company-below:hover {
  background-color: var(--primary-light);
  color: var(--primary-color);
  border-color: var(--primary-color);
}

/* Remove the inside-logo button styles */
.btn-edit-company-subtle {
  display: none;
}

/* Original button remains hidden */
.btn-edit-company {
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .company-logo-section {
    width: 100%;
    max-width: 180px;
  }

  .btn-edit-company-below {
    max-width: 180px;
  }
}

/* Company logo image styling */
.company-logo-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  /* Azure CDN-optimalisert bildehåndtering */
  will-change: transform;
}

.company-logo-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-light);
  color: white;
  font-size: 3rem; /* Beholder stor font for placeholder-bokstav */
  font-weight: bold;
}

/* Company details styling */
.company-details-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.company-header-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 0.75rem;
  /* Remove any borders */
  border-bottom: none;
}

.company-name {
  font-size: var(--font-2xl); /* Redusert fra 2.25rem til 1.875rem */
  color: var(--text-dark);
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
}

.btn-edit-company {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: var(--font-sm); /* Redusert knappetekststørrelse */
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  transition: var(--transition);
}

.btn-edit-company:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.company-category {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-medium);
  font-size: var(--font-sm); /* Redusert fra 1rem til 0.875rem */
  margin-top: -0.25rem; /* Justert margin */
}

.category-icon {
  color: var(--primary-color);
}

.company-description {
  color: var(--text-medium);
  font-size: var(--font-base); /* Standard 1rem (16px) */
  line-height: 1.4; /* Reduced from 1.6 */
  margin-top: 0.25rem; /* Reduced from 0.5rem */
  /* Remove any borders */
  border: none;
}

.company-description p {
  margin: 0;
}

/* Links section */
.company-links-container {
  background-color: var(--bg-light);
  padding: 0.5rem 0.75rem; /* Reduced from 0.75rem 1rem */
  /* Remove the top border */
  border-top: none !important;
}

.company-links-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.5rem;
}

.company-website-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: var(--transition);
  font-size: var(--font-sm); /* Redusert fra base størrelse */
}

.company-website-link:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}

.social-links-wrapper {
  display: flex;
  align-items: center;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.social-link {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background-color: white;
  border: 1px solid var(--border-light);
  border-radius: 20px;
  color: var(--primary-color);
  text-decoration: none;
  font-size: var(--font-xs); /* Redusert til 12px */
  transition: var(--transition);
}

.social-link:hover {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
  transform: translateY(-2px);
}

/* Projects section */
.projects-section {
  background-color: white;
  border-radius: var(--radius);
  box-shadow: var(--shadow-md);
  padding: 1rem; /* Reduced from 1.5rem */
  /* Remove any borders if present */
  border: none;
  /* Add these properties to fix mobile layout */
  width: 100%;
  overflow: visible;
  position: relative;
}

.projects-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.section-title {
  font-size: var(--font-lg); /* Redusert fra 1.5rem til 1.25rem */
  color: var(--text-dark);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
}

.section-title svg {
  color: var(--primary-color);
}

.projects-filter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.sort-control {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background-color: var(--bg-light);
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.sort-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-medium);
  margin: 0;
  font-size: var(--font-xs); /* Redusert fra 0.9rem til 0.75rem */
}

.sort-select {
  border: 1px solid var(--border-light);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: var(--font-xs); /* Redusert fra 0.9rem til 0.75rem */
  color: var(--text-dark);
  background-color: white;
  min-width: 140px;
}

/* Projects gallery */
.projects-gallery {
  margin-top: 1.5rem;
  /* Add padding to match the feed spacing */
  padding: 0.5rem 0;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(230px, 1fr)
  ); /* Slightly smaller cards */
  gap: 1.25rem; /* Slightly reduced gap */
}

.project-card-wrapper {
  height: 100%;
}

.project-card {
  background-color: white;
  border-radius: var(--radius);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  /* Optimalisere for bedre CSS-ytelse */
  will-change: transform;
  backface-visibility: hidden;
  /* Remove any borders */
  border: none;
  transition:
    transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1),
    box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
  /* Remove any border changes on hover */
  border: none;
}

.project-thumbnail {
  position: relative;
  height: 160px; /* Redusert fra 180px */
  overflow: hidden;
}

.project-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

.placeholder-img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    var(--primary-light) 0%,
    var(--primary-color) 100%
  );
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
}

.project-category-badge {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 0.2rem 0.6rem;
  border-radius: 20px;
  font-size: 0.75rem;
  backdrop-filter: blur(4px);
}

.project-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.project-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-dark);
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.project-description {
  font-size: 0.9rem;
  color: var(--text-medium);
  line-height: 1.5;
  margin: 0;
}

/* Empty state styling */
.empty-projects-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.empty-projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  background-color: var(--bg-light);
  border-radius: var(--radius);
  text-align: center;
}

.empty-projects-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.dummy-projects-container {
  margin-top: 1rem;
}

.dummy-projects-header {
  font-size: 1.1rem;
  color: var(--text-medium);
  margin-bottom: 1rem;
  text-align: center;
}

.dummy-card {
  position: relative;
  opacity: 0.75;
  border: 2px dashed var(--border-light);
  transition:
    opacity 0.3s ease,
    border 0.3s ease;
}

.dummy-card:hover {
  opacity: 0.9;
  border: 2px dashed var(--primary-light);
}

.dummy-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(85, 131, 146, 0.8);
  color: white;
  padding: 0.2rem 0.6rem;
  border-radius: 20px;
  font-size: 0.75rem;
  z-index: 10;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .company-header-container {
    grid-template-columns: 150px 1fr;
  }

  .company-logo-container {
    width: 150px;
    height: 150px;
  }

  .company-name {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .company-header-container {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  .company-logo-container {
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }

  .company-header-top {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .company-name {
    text-align: center;
  }

  .company-category,
  .company-description {
    text-align: center;
  }

  .projects-header {
    flex-direction: column;
    gap: 1rem;
  }

  .company-content-wrapper {
    height: auto !important;
    min-height: 100% !important;
  }

  .company-content {
    overflow: visible !important;
    height: auto !important;
  }

  .projects-section {
    padding: 1rem;
    overflow: visible;
  }

  /* Ensure project cards are visible */
  .projects-grid {
    grid-template-columns: 1fr !important;
    gap: 1rem;
  }

  .project-card {
    margin-bottom: 1rem;
  }
}

@media (max-width: 576px) {
  .company-links-inner {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }

  .sort-control {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .company-profile {
    padding: 0.25rem 0.5rem;
    overflow: visible;
  }

  .projects-section {
    padding: 0.75rem;
    margin-bottom: 1rem;
  }
}

/* Compact layout styles - add these to your CSS file */
.compact-layout {
  display: grid;
  grid-template-columns: 180px 1fr; /* Logo width and flexible content */
  grid-gap: 1.5rem;
  padding: 1.5rem;
  align-items: flex-start;
}

/* Company info wrapper for the right side of the header */
.company-info-wrapper {
  position: relative;
  padding-right: 2rem; /* Space for the edit button */
}

/* Company name - larger and more prominent */
.company-name {
  font-size: var(--font-2xl);
  color: var(--text-dark);
  margin: 0 0 0.25rem 0;
  font-weight: 700;
  line-height: 1.2;
}

/* Move the category indicator directly under the name */
.company-category {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-medium);
  font-size: var(--font-sm);
  margin-bottom: 0.75rem;
}

/* About section directly in the company info wrapper */
.company-description {
  color: var(--text-medium);
  font-size: var(--font-base);
  line-height: 1.6;
  padding-top: 0.5rem;
}

.company-description p {
  margin: 0;
}

/* Corner edit button */
.btn-edit-company-corner {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 0.75rem;
  background-color: transparent;
  border: 1px solid var(--border-light);
  border-radius: var(--radius);
  color: var(--text-medium);
  font-size: var(--font-xs);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.btn-edit-company-corner:hover {
  background-color: var(--primary-light);
  color: var(--primary-color);
  border-color: var(--primary-color);
}

/* Mobile responsiveness for compact layout */
@media (max-width: 768px) {
  .compact-layout {
    grid-template-columns: 100px 1fr; /* Smaller logo on mobile */
    grid-gap: 1rem;
    padding: 1rem;
  }

  .company-name {
    font-size: var(--font-xl); /* Smaller title on mobile */
  }

  .company-description {
    font-size: var(--font-sm); /* Smaller description text on mobile */
  }

  .company-logo-container {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 480px) {
  .compact-layout {
    grid-template-columns: 1fr; /* Stack vertically on very small screens */
    text-align: center;
  }

  .company-logo-section {
    justify-self: center;
    margin-bottom: 1rem;
  }

  .company-info-wrapper {
    text-align: center;
    padding-right: 0;
  }

  .company-category {
    justify-content: center;
  }

  .btn-edit-company-corner {
    position: static;
    margin: 1rem auto 0;
    width: fit-content;
  }
}

/* Reduce space between header and projects */
.company-profile > div[style*='height: 1.5rem'] {
  height: 0.75rem !important; /* Reduced from 1.5rem */
}
