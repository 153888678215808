.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.spinner-circle {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(85, 131, 146, 0.2);
  border-top: 3px solid #558392;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner-text {
  margin-top: 12px;
  color: #558392;
  font-size: 0.9rem;
  font-weight: 500;
}

/* Forskjellige størrelser */
.spinner-sm .spinner-circle {
  width: 24px;
  height: 24px;
  border-width: 2px;
}

.spinner-lg .spinner-circle {
  width: 60px;
  height: 60px;
  border-width: 4px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
