.company-edit-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.company-edit-header {
  background-color: var(--primary-color);
  color: white;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company-edit-header h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

/* Tab Navigation Styling */
:root {
  --primary-color: #558392;
  --primary-light: #88a7b3;
  --primary-dark: #406b77;
  --text-dark: #333;
  --text-medium: #666;
  --text-light: #999;
}

/* Tab container with connecting line */
.company-edit-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 2rem 0 3rem;
  padding: 0 1rem;
}

/* Horizontal connecting line */
.company-edit-tabs::after {
  content: '';
  position: absolute;
  top: 15px;
  left: 50%;
  width: 70%;
  height: 2px;
  background-color: #e0e0e0;
  transform: translateX(-50%);
  z-index: 1;
}

/* Individual tab */
.edit-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  margin: 0 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Tab indicator (circle) */
.edit-tab::before {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e0e0e0;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
}

/* Active tab styling */
.edit-tab.active::before {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transform: scale(1.1);
  box-shadow: 0 0 0 4px rgba(85, 131, 146, 0.2);
}

/* Tab label text */
.edit-tab-label {
  font-size: 0.9rem;
  color: var(--text-medium);
  margin-top: 5px;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.edit-tab.active .edit-tab-label {
  color: var(--primary-color);
  font-weight: 600;
}

/* Hover effects for tabs */
.edit-tab:not(.active):hover::before {
  border-color: var(--primary-light);
  transform: scale(1.05);
}

.edit-tab:not(.active):hover .edit-tab-label {
  color: var(--primary-light);
}

/* Tab number inside circle */
.edit-tab-indicator {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-medium);
  font-size: 0.85rem;
  font-weight: 600;
  z-index: 3;
}

.edit-tab.active .edit-tab-indicator {
  color: #fff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .company-edit-tabs {
    flex-wrap: wrap;
    margin: 1.5rem 0;
  }

  .company-edit-tabs::after {
    width: 60%;
  }

  .edit-tab {
    margin: 0 15px 15px;
  }

  .edit-tab-label {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .company-edit-tabs {
    flex-direction: row;
    justify-content: space-between;
  }

  .company-edit-tabs::after {
    width: 90%;
  }

  .edit-tab {
    margin: 0 5px 15px;
  }

  .edit-tab::before {
    width: 25px;
    height: 25px;
  }

  .edit-tab-indicator {
    top: 13px;
    font-size: 0.75rem;
  }
}

.company-edit-content {
  padding: 1.5rem;
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  flex: 1;
  min-width: 0; /* Prevents flex items from overflowing */
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--text-dark);
  font-size: 0.9rem;
}

.form-hint {
  display: block;
  font-size: 0.8rem;
  color: var(--text-medium);
  margin-top: 0.25rem;
}

/* Legg til følgende stilregler */

.logo-preview-container {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
  border: 1px dashed #ddd;
  border-radius: var(--border-radius-sm);
  padding: 15px;
  height: 150px;
}

.logo-preview-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.logo-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #aaa;
  height: 100%;
  width: 100%;
}

.logo-placeholder i {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.logo-upload-controls {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input + label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 8px 16px;
  font-weight: 500;
}

.file-input:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

/* Stil for å vise navnet på den valgte filen */
.selected-file-name {
  display: inline-block;
  margin-left: 10px;
  font-size: 0.9rem;
  color: var(--text-medium);
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

/* Oppdater lagre-knappen for å gjøre den mer fremtredende når det finnes en logo-fil */
.form-actions .btn-primary {
  transition: all 0.3s ease;
  position: relative;
}

/* Legg til en subtil indikator når det finnes endringer å lagre */
.form-actions .btn-primary.has-changes {
  background-color: var(--primary-color);
  box-shadow: 0 2px 10px rgba(85, 131, 146, 0.3);
}

/* Responsive justering for filopplastingskontroller */
@media (max-width: 576px) {
  .logo-upload-controls {
    flex-direction: column;
    align-items: flex-start;
  }

  .selected-file-name {
    margin-left: 0;
    margin-top: 5px;
    max-width: 100%;
  }
}

/* Add these styles to your CSS file */

.website-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.protocol-prefix {
  position: absolute;
  left: 12px;
  color: #666;
  font-weight: 500;
  pointer-events: none;
  z-index: 2;
}

.with-prefix {
  padding-left: 72px !important; /* Make room for the prefix */
}

/* In dark mode if applicable */
@media (prefers-color-scheme: dark) {
  .protocol-prefix {
    color: #999;
  }
}
