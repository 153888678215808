.project-gallery {
  margin-top: 1.5rem;
}

/* Update header to display actions side by side */
.project-gallery-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

/* Add new container for button and filter to position them side by side */
.header-actions {
  display: flex;
  align-items: center;
  gap: 12px;
}

/* Keep section title styling but ensure it takes minimal width */
.section-title {
  font-size: 1.25rem;
  color: #333;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  flex-shrink: 0;
}

.section-title i {
  color: #558392;
}

/* Make create button more prominent */
.create-project-btn {
  background-color: #558392;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition:
    background-color 0.2s,
    transform 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.create-project-btn:hover {
  background-color: #3e6270;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
}

/* Fix for empty space below action buttons */
.project-card {
  position: relative;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  padding-bottom: 0; /* Fjernet 60px padding som skapte tomrom */
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.project-card.deleting {
  animation: fadeOut 0.5s forwards;
  pointer-events: none;
  opacity: 0.7;
  transform: scale(0.98) translateY(8px);
  transition: all 0.3s ease;
}

@keyframes fadeOut {
  from {
    opacity: 0.7;
    transform: scale(0.98) translateY(8px);
  }
  to {
    opacity: 0;
    transform: scale(0.95) translateY(16px);
  }
}

.project-thumbnail {
  position: relative;
  height: 160px;
  overflow: hidden;
}

.project-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder-img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #558392 0%, #3498db 100%);
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

.project-category-badge {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 0.75rem;
  backdrop-filter: blur(4px);
}

/* Sørg for at innholdsområdet fyller tilgjengelig plass */
.project-content {
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.project-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.project-description {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
  line-height: 1.5;
}

/* Juster action-området for å fjerne ekstra padding */
.project-actions {
  display: flex;
  padding: 0.75rem 1rem;
  border-top: 1px solid #eee;
  gap: 0.5rem;
  margin-top: auto; /* Skyver handlingsknappene til bunnen av kortet */
}

.project-actions button {
  flex: 1;
  padding: 8px;
  font-size: 0.85rem;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition: all 0.2s;
}

.btn-gantt {
  background-color: #558392;
  color: white;
  border: none;
}

.btn-gantt:hover {
  background-color: #466d7a;
}

.btn-mobile {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
}

.btn-mobile:hover {
  background-color: #e5e5e5;
}

.project-actions .btn-delete {
  background-color: rgba(231, 76, 60, 0.1);
  color: #e74c3c;
  transition: all 0.2s ease;
}

.project-actions .btn-delete:hover {
  background-color: #e74c3c;
  color: white;
}

.project-actions .btn-delete:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.project-delete-btn {
  display: none;
}

.project-delete-btn .delete-text {
  margin-left: 8px;
  font-size: 14px;
}

.empty-projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3rem 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.empty-projects-icon {
  font-size: 3rem;
  color: #ccc;
  margin-bottom: 1rem;
}

.empty-projects h4 {
  font-size: 1.2rem;
  margin: 0 0 0.5rem;
}

.empty-projects p {
  color: #666;
  margin-bottom: 1.5rem;
}

.delete-toast {
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.delete-toast h4 {
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 600;
  color: #e74c3c;
}

.delete-toast p {
  margin-bottom: 16px;
  color: #333;
  font-size: 14px;
}

.toast-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.toast-actions button {
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
}

.toast-actions .btn-cancel {
  background-color: #e0e0e0;
  color: #333;
}

.toast-actions .btn-delete {
  background-color: #e74c3c;
  color: white;
}

/* Make admin status more prominent */
.admin-status-indicator {
  padding: 8px 12px;
  margin: 15px 0;
  background-color: #e6f7e6;
  color: #2e7d32;
  border-radius: 4px;
  font-size: 0.9rem;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-status-indicator.warning {
  background-color: #fff3e0;
  color: #e65100;
}

/* Task Action style delete button */
.btn-icon.project-delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.9);
  color: #e74c3c;
  border: none;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-icon.project-delete-icon:hover {
  background: #e74c3c;
  color: white;
  transform: scale(1.05);
}

.btn-icon.project-delete-icon:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.delete-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  position: absolute;
  animation: delete-spin 1.2s linear infinite;
}

@keyframes delete-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Update filter container positioning for inline display */
.filter-dropdown-container {
  position: relative;
  z-index: 100;
  margin-bottom: 0; /* Remove margin since it's now inline */
  width: auto; /* Allow it to size based on content */
}

/* Improve filter button visibility and style matching */
.filter-dropdown-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #558392; /* Match the primary button color */
  color: white; /* White text for better contrast */
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-dropdown-toggle:hover {
  background-color: #3e6270; /* Darker shade on hover */
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Update dropdown positioning for new layout */
.filter-dropdown-menu {
  position: absolute;
  top: calc(100% + 8px);
  right: 0; /* Align to right side of the filter button */
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 101;
  width: auto;
}

/* Improve selected filter visibility */
.selected-filter {
  background-color: #fff;
  color: #558392;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  margin-left: 6px;
  font-weight: 600;
}

/* Fixed dropdown menu text visibility */
.filter-dropdown-menu button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #333; /* Ensuring text is visible */
}

.filter-dropdown-menu button:hover {
  background-color: #f5f5f5;
}

.filter-dropdown-menu button.active {
  background-color: #558392;
  color: white;
  font-weight: 500;
}

/* Improved pagination controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 2rem;
}

.pagination-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  background-color: #558392;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.pagination-button:hover {
  background-color: #3e6270;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pagination-button:disabled {
  background-color: #cccccc;
  color: #888888;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.pagination-info {
  font-size: 0.9rem;
  color: #666;
}

.nav-button {
  display: none;
}

.navigation-controls {
  display: none;
}

/* New Pagination Style - Circular Step Indicator */
:root {
  --primary-color: #558392;
  --primary-light: #88a7b3;
  --primary-dark: #406b77;
  --text-medium: #666;
}

.pagination-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 2rem 0;
  padding: 0 1rem;
}

/* Horizontal connecting line */
.pagination-steps::after {
  content: '';
  position: absolute;
  top: 15px;
  left: 50%;
  width: 80%;
  height: 2px;
  background-color: #e0e0e0;
  transform: translateX(-50%);
  z-index: 1;
}

/* Individual page indicator */
.page-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Circle for the page indicator */
.page-indicator::before {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e0e0e0;
  margin-bottom: 8px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
}

/* Active page styling */
.page-indicator.active::before {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transform: scale(1.1);
  box-shadow: 0 0 0 4px rgba(85, 131, 146, 0.2);
}

/* Page number inside circle */
.page-number {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-medium);
  font-size: 0.85rem;
  font-weight: 600;
  z-index: 3;
}

.page-indicator.active .page-number {
  color: #fff;
}

/* Dots indicator for many pages */
.page-dots {
  display: flex;
  align-items: center;
  margin: 0 5px;
  color: var(--text-medium);
  font-weight: bold;
  letter-spacing: 2px;
  position: relative;
  z-index: 2;
}

/* Mobile optimization */
@media (max-width: 576px) {
  .pagination-steps::after {
    width: 90%;
  }

  .page-indicator {
    margin: 0 6px;
  }

  .page-indicator::before {
    width: 24px;
    height: 24px;
  }

  .page-number {
    font-size: 0.75rem;
    top: 12px;
  }
}

@media (max-width: 768px) {
  .project-gallery-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .projects-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }

  .header-actions {
    width: 100%;
    justify-content: space-between;
  }

  .filter-dropdown-container {
    flex: 1; /* Allow it to grow */
    max-width: 48%; /* Prevent it from taking too much space */
  }

  .create-project-btn {
    flex: 1;
    max-width: 48%;
  }
}

@media (max-width: 480px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }

  .project-actions {
    flex-direction: column;
  }

  .project-gallery-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .header-actions {
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }

  .filter-dropdown-container,
  .create-project-btn {
    width: 100%;
    max-width: 100%;
  }
}

/* Mobile responsiveness fixes */
@media (max-width: 768px) {
  .project-gallery {
    width: 100%;
    overflow-y: visible;
    margin-bottom: 2rem;
  }

  .projects-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
  }

  .project-card {
    margin-bottom: 1rem;
    width: 100%;
  }
}

/* Fix to allow scrolling on mobile devices */
@media (max-width: 576px) {
  .company-layout {
    height: auto;
    min-height: 100vh;
    overflow-y: auto;
  }

  .company-content-wrapper {
    height: auto !important;
    overflow: visible !important;
  }

  .company-content {
    overflow-y: visible !important;
    height: auto !important;
  }

  .projects-section {
    overflow: visible;
    margin-bottom: 3rem;
  }

  .project-gallery {
    padding-bottom: 2rem;
  }
}
