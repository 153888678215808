:root {
  --primary-color: #558392; /* Blue-teal as primary brand color */
  --primary-light: rgba(85, 131, 146, 0.1);
  --primary-medium: rgba(85, 131, 146, 0.4);
  --accent-color: #ff9800; /* Orange as accent color */
  --accent-hover: #ffb74d;
  --text-dark: #2c3e50;
  --text-medium: #546e7a;
  --text-light: #ffffff;
  --bg-light: #ffffff;
  --bg-medium: #f5f7f9;
  --shadow-soft: 0 5px 15px rgba(0, 0, 0, 0.05);
  --shadow-medium: 0 8px 30px rgba(0, 0, 0, 0.12);
  --border-radius-sm: 8px;
  --border-radius-md: 12px;
  --border-radius-lg: 20px;
  --transition-fast: 0.2s ease;
  --transition-medium: 0.3s ease;
  --font-family: 'Roboto', sans-serif;
}

/* Profile Card Base Style */
.profile-card {
  display: flex;
  background-color: var(--bg-light);
  border-radius: var(--border-radius-sm);
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: var(--shadow-soft);
  align-items: center;
  transition:
    transform var(--transition-fast),
    box-shadow var(--transition-medium);
}

.profile-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-medium);
}

/* Profile Image */
.profile-card-image {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 15px;
  border: 2px solid var(--primary-light);
  transition: transform var(--transition-medium);
}

.profile-card:hover .profile-card-image {
  transform: scale(1.05);
}

/* Profile Text Content */
.profile-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-card-name,
.profile-card-company,
.profile-card-email {
  margin: 0;
  padding: 2px 0;
}

.profile-card-name {
  font-weight: 600;
  font-size: 1rem;
  color: var(--text-dark);
}

.profile-card-company {
  color: var(--text-medium);
  font-size: 0.9rem;
}

/* Update the email styling */

/* Email specific styling with clickable link behavior */
.profile-card-email {
  color: var(--primary-color);
  font-size: 0.85rem;
  position: relative;
  display: inline-block;
  text-decoration: none;
  transition: all var(--transition-fast);
  cursor: pointer;
}

/* Add underline effect on email hover */
.profile-card-email:hover {
  text-decoration: underline;
  color: var(--accent-color);
}

/* Add a subtle indicator that it's clickable */
.profile-card-email::after {
  content: '✉';
  display: inline-block;
  margin-left: 4px;
  font-size: 0.8em;
  opacity: 0;
  transform: translateX(-5px);
  transition: all var(--transition-fast);
}

.profile-card-email:hover::after {
  opacity: 1;
  transform: translateX(0);
}

/* Profile Actions */
.profile-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Follow Button */
.follow-button {
  padding: 8px 16px;
  border: none;
  background-color: var(--primary-color);
  color: white;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: 500;
  transition: all var(--transition-fast);
}

.follow-button:hover {
  background-color: var(--primary-color);
  opacity: 0.9;
  transform: translateY(-2px);
}

.follow-button.following {
  background-color: var(--bg-medium);
  color: var(--text-medium);
  border: 1px solid var(--text-medium);
}

.follow-button.following:hover {
  background-color: #f1f1f1;
  color: var(--text-dark);
}

/* Edit Profile Button */
.edit-profile-btn {
  padding: 8px 16px;
}

/* Text elements styling fix - update only these rules */

/* Profile Text Content - Base styles without underlines */
.profile-details p {
  margin: 0;
  padding: 2px 0;
  position: relative;
  transition: color var(--transition-fast);
}

/* Remove any existing underline effects on hover */
.profile-card:hover .profile-details p::after {
  display: none;
}

/* Name styling */
.profile-card-name {
  font-weight: 600;
  font-size: 1rem;
  color: var(--text-dark);
}

/* Company styling */
.profile-card-company {
  color: var(--text-medium);
  font-size: 0.9rem;
}

/* If the underline is being added with pseudo-elements, make sure they only appear for email */
.profile-card-name::after,
.profile-card-company::after {
  display: none;
}

/* Add this to your existing SocialCards.css file */

/* Company link styling */
.company-link {
  color: var(--primary-color);
  text-decoration: none;
  position: relative;
  transition: all var(--transition-fast);
  cursor: pointer;
  display: inline-block;
}

.company-link:hover {
  color: var(--accent-color);
}

/* Add a subtle indicator that it's clickable */
.company-link::after {
  content: '→';
  display: inline-block;
  margin-left: 4px;
  font-size: 0.8em;
  opacity: 0;
  transform: translateX(-5px);
  transition: all var(--transition-fast);
}

.company-link:hover::after {
  opacity: 1;
  transform: translateX(0);
}
