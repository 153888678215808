:root {
  --primary-color: #558392; /* Blue-teal as primary brand color */
  --primary-light: rgba(85, 131, 146, 0.1);
  --primary-medium: rgba(85, 131, 146, 0.4);
  --accent-color: #ff9800; /* Orange as accent color */
  --accent-hover: #ffb74d;
  --text-dark: #2c3e50;
  --text-medium: #546e7a;
  --text-light: #ffffff;
  --bg-light: #ffffff;
  --bg-medium: #f5f7f9;
  --shadow-soft: 0 5px 15px rgba(0, 0, 0, 0.05);
  --shadow-medium: 0 8px 30px rgba(0, 0, 0, 0.12);
  --border-radius-sm: 8px;
  --border-radius-md: 12px;
  --border-radius-lg: 20px;
  --transition-fast: 0.2s ease;
  --transition-medium: 0.3s ease;
  --font-family: 'Roboto', sans-serif;
}

.company-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Changed from fixed height 100vh */
  background-color: var(--bg-light);
  font-family: var(--font-family);
}

/* Add consistent padding around NavSteps */
.company-layout > .nav-steps-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.company-content-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
  margin-top: 0; /* Remove top margin */
}

.company-content {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  background-color: white;
}

.company-header-nav {
  padding: 0.5rem 1rem; /* Reduced from 1rem 1.5rem */
  display: flex;
  align-items: center;
  background-color: var(--bg-light);
  border-bottom: 1px solid var(--primary-light);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.company-page-title {
  margin: 0 0 0 1rem;
  font-size: 1.25rem; /* Reduced from 1.5rem */
  color: var(--text-dark);
  font-weight: 600;
}

.back-button {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 500;
  border: none;
  background: transparent;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  transition: all var(--transition-fast);
  border-radius: var(--border-radius-sm);
}

.back-button:hover {
  color: var(--accent-color);
  background-color: var(--primary-light);
  transform: translateY(-2px);
}

.back-button:active {
  transform: translateY(0) scale(0.98);
}

.company-content-container {
  padding: 0.75rem 1rem; /* Reduced from 1.5rem */
  flex: 1;
}

/* Card styling for company content */
.company-profile,
.create-company-container,
.edit-mode-container {
  background-color: var(--bg-light);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-soft);
  transition:
    transform var(--transition-medium),
    box-shadow var(--transition-medium);
  padding: 1rem; /* Reduced from 1.5rem */
  margin-bottom: 1rem; /* Reduced from 1.5rem */
}

.company-profile:hover,
.edit-mode-container:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-medium);
}

/* Button styling to match Portal */
.btn {
  padding: 10px 20px;
  border-radius: var(--border-radius-sm);
  font-weight: 500;
  cursor: pointer;
  transition: all var(--transition-fast);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: var(--accent-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(255, 152, 0, 0.3);
}

.btn-outline-primary {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background: transparent;
}

.btn-outline-primary:hover {
  background-color: var(--primary-light);
  transform: translateY(-2px);
}

/* Azure-recommended responsive approach */
@media (max-width: 768px) {
  .company-content-wrapper {
    flex-direction: column;
  }

  .company-sidebar {
    width: 100%;
    max-height: 300px;
    border-right: none;
    border-bottom: 1px solid var(--primary-light);
  }

  .company-header-nav {
    padding: 0.75rem 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .company-page-title {
    font-size: 1.25rem;
  }

  .company-content-container {
    padding: 1rem;
  }

  .mobile-no-sidebar .company-content-wrapper {
    display: block;
  }

  .mobile-no-sidebar .company-content {
    padding-left: 0;
  }

  .company-content.full-width .company-content-container {
    padding: 0.5rem;
  }

  /* Improve title display when in mobile full-width mode */
  .mobile-no-sidebar .company-header-nav {
    padding: 0.75rem;
    border-bottom: 1px solid var(--border-light);
  }

  .mobile-no-sidebar .company-page-title {
    font-size: 1.25rem;
    margin-left: 0.5rem;
  }

  .company-layout > .nav-steps-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .company-layout {
    overflow-y: auto; /* Changed from overflow: hidden */
  }

  .company-content {
    overflow-y: visible;
  }

  .company-content-container {
    overflow: visible;
    padding-bottom: 3rem; /* Add space at bottom for better UX */
  }
}

/* Animation for page transitions */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.company-profile,
.create-company-container,
.edit-mode-container {
  animation: fadeIn 0.4s ease-out;
}

/* Added mobile-specific styles for hiding sidebar */
.mobile-no-sidebar .company-sidebar {
  display: none;
}

.company-content.full-width {
  width: 100%;
  max-width: 100%;
}
