/* Make NavSteps container consistent between Home and Company pages */
.nav-steps-container {
  padding: 0.75rem 0; /* Reduced from 1.5rem */
  background-color: white;
  border-radius: 0; /* Flat by default */
  box-shadow: none; /* No shadow by default */
  margin-top: 0; /* Reset any margin */
  margin-bottom: 0.75rem; /* Reduced from 1.5rem */
  width: 100%;
}

/* Add shadow only in specific contexts */
.home-container .nav-steps-container {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* Ensure consistent spacing in CompanyLayout */
.company-layout .nav-steps-container {
  margin-top: 1rem; /* Match the spacing from navbar in home page */
}

/* Nav steps styling using the circular indicators */
.nav-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 0.5rem; /* Reduced from 1rem */
}

/* Horizontal connecting line */
.nav-steps::after {
  content: '';
  position: absolute;
  top: 11px; /* Adjusted from 15px */
  left: 50%;
  width: 70%;
  height: 2px;
  background-color: #e0e0e0;
  transform: translateX(-50%);
  z-index: 1;
}

/* Individual nav step */
.nav-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  margin: 0 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Disabled step styling */
.nav-step.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Circle for the step indicator */
.nav-step .step-indicator {
  width: 22px; /* Reduced from 30px */
  height: 22px; /* Reduced from 30px */
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e0e0e0;
  margin-bottom: 4px; /* Reduced from 10px */
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Active step styling */
.nav-step.active .step-indicator {
  background-color: var(--primary-color, #558392);
  border-color: var(--primary-color, #558392);
  transform: scale(1.1);
  box-shadow: 0 0 0 4px rgba(85, 131, 146, 0.2);
}

/* Step label text */
.step-label {
  font-size: 0.8rem; /* Reduced from 0.9rem */
  color: var(--text-medium, #666);
  margin-top: 2px; /* Reduced from 5px */
  transition: all 0.3s ease;
  white-space: nowrap;
}

.nav-step.active .step-label {
  color: var(--primary-color, #558392);
  font-weight: 600;
}

/* Hover effects for steps */
.nav-step:not(.active):not(.disabled):hover .step-indicator {
  border-color: var(--primary-light, #88a7b3);
  transform: scale(1.05);
}

.nav-step:not(.active):not(.disabled):hover .step-label {
  color: var(--primary-light, #88a7b3);
}

/* Responsive styling */
@media (max-width: 768px) {
  .nav-steps {
    padding: 0 0.5rem;
  }

  .nav-step {
    margin: 0 10px;
  }

  .nav-steps::after {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .nav-steps {
    padding: 0;
  }

  .nav-step {
    margin: 0 5px;
  }

  .nav-step .step-indicator {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
  }

  .step-label {
    font-size: 0.75rem;
  }

  .nav-steps::after {
    width: 90%;
  }
}
