:root {
  --primary-color: #558392;
  --primary-light: #88a7b3;
  --primary-dark: #406b77;
  --text-dark: #333;
  --text-medium: #666;
  --text-light: #999;
  --border-light: #e0e0e0;
  --bg-light: #f8f9fa;
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.08);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
  --radius: 6px;

  /* Reduced font sizes */
  --font-xs: 0.7rem; /* 11.2px */
  --font-sm: 0.8rem; /* 12.8px */
  --font-base: 0.875rem; /* 14px */
  --font-md: 1rem; /* 16px */
  --font-lg: 1.125rem; /* 18px */
  --font-xl: 1.25rem; /* 20px */
  --font-2xl: 1.5rem; /* 24px - reduced from 1.875rem */
}

/* Project form container - now an inline form rather than modal */
.project-form-container {
  background-color: #fff;
  border-radius: var(--radius);
  box-shadow: var(--shadow-md);
  margin-bottom: 1.5rem; /* Reduced from 2rem */
  overflow: hidden;
  animation: slideDown 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Form header */
.project-form-header {
  background-color: var(--primary-color);
  background-image: linear-gradient(
    45deg,
    var(--primary-color),
    var(--primary-dark)
  );
  color: white;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: none;
}

.project-form-header h2 {
  margin: 0;
  font-size: var(--font-xl);
  font-weight: 500;
  color: white;
}

.close-button {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  font-size: 1.25rem;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.close-button:hover {
  opacity: 1;
  transform: rotate(90deg);
  background-color: rgba(255, 255, 255, 0.2);
}

/* Project form styles */
.project-form {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--text-dark);
  font-size: var(--font-sm);
}

.form-control {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  font-size: var(--font-base);
  transition:
    border-color 0.2s,
    box-shadow 0.2s;
}

.form-control:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(85, 131, 146, 0.2);
}

.form-control.error {
  border-color: #dc3545;
}

.error-text {
  color: #dc3545;
  font-size: var(--font-xs);
  margin-top: 0.25rem;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  font-weight: 500;
  color: var(--text-dark);
  user-select: none;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.toggle-slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: var(--primary-color);
}

input:focus + .toggle-slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .toggle-slider:before {
  transform: translateX(24px);
}

.create-project-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

/* Fixed modal backdrop and hover styles */

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Change from black to a gradient with brand colors */
  background: linear-gradient(
    135deg,
    rgba(85, 131, 146, 0.85) 0%,
    rgba(52, 73, 94, 0.8) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.create-project-modal {
  background-color: #fff;
  border-radius: var(--radius);
  box-shadow: var(--shadow-lg);
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  animation: modalSlideIn 0.3s ease;
}

.create-project-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #e0e0e0;
}

.create-project-modal-header h3 {
  margin: 0;
  color: #333;
  font-size: 1.25rem;
}

.modal-header {
  background-color: var(--primary-color);
  color: white;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: none;
  margin-bottom: 0;
}

.modal-header h2 {
  margin: 0;
  font-size: var(--font-xl);
  font-weight: 500;
  color: white;
}

.modal-close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

.close-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 1.25rem;
  cursor: pointer;
  transition: transform 0.2s;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.close-button:hover {
  opacity: 1;
  transform: rotate(90deg);
  background-color: rgba(255, 255, 255, 0.1);
}

.create-project-form {
  padding: 20px;
}

.project-form {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  color: #555;
}

.form-group .icon {
  color: #558392;
}

.form-group input[type='text'],
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--text-dark);
  font-size: var(--font-sm);
}

.form-control {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  font-size: var(--font-base);
  transition:
    border-color 0.2s,
    box-shadow 0.2s;
}

.form-control:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(85, 131, 146, 0.2);
}

.form-control.error {
  border-color: #dc3545;
}

.error-text {
  color: #dc3545;
  font-size: var(--font-xs);
  margin-top: 0.25rem;
}

.form-group textarea {
  resize: vertical;
  min-height: 80px;
}

.form-group-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-group-checkbox label {
  margin-bottom: 0;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  font-weight: 500;
  color: var(--text-dark);
  user-select: none;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.toggle-slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: var(--primary-color);
}

input:focus + .toggle-slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .toggle-slider:before {
  transform: translateX(24px);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--border-light);
}

.btn {
  padding: 0.6rem 1.25rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background-color: var(--primary-dark);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.btn-primary:active {
  transform: translateY(0);
}

.btn-primary:disabled {
  background-color: var(--primary-light);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.btn-outline-secondary {
  background-color: white;
  color: #666;
  border: 1px solid #ddd;
}

.btn-outline-secondary:hover {
  background-color: #f5f5f5;
}

.btn-secondary {
  background-color: #f0f2f5;
  color: var(--text-dark);
}

.btn-secondary:hover {
  background-color: #e4e6e9;
  transform: translateY(-2px);
}

.btn-secondary:active {
  transform: translateY(0);
}

.btn-outline-secondary {
  background-color: transparent;
  color: var(--text-medium);
  border: 1px solid var(--border-light);
}

.btn-outline-secondary:hover {
  background-color: var(--bg-light);
  border-color: var(--text-light);
}

.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 0.75rem 1rem;
  border-radius: var(--radius);
  margin-bottom: 1rem;
  border-left: 4px solid #f5c6cb;
  font-size: var(--font-sm);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add these to your existing ProjectModal.css file */

.tasks-section {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--border-light);
}

.tasks-section button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.initial-tasks-container {
  margin-top: 1.5rem;
  background-color: var(--bg-light);
  padding: 1.5rem;
  border-radius: var(--radius);
  animation: fadeIn 0.3s ease;
}

.initial-tasks-container h3 {
  font-size: var(--font-md);
  color: var(--text-dark);
  margin-top: 0;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.task-form {
  background-color: white;
  padding: 1.25rem;
  border-radius: var(--radius);
  box-shadow: var(--shadow-sm);
  margin-bottom: 1.5rem;
  border: 1px solid var(--border-light);
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.half-width {
  flex: 1;
  min-width: 0;
}

.task-list {
  margin-top: 1.5rem;
}

.task-list h4 {
  font-size: var(--font-base);
  margin-bottom: 1rem;
  color: var(--text-dark);
  font-weight: 500;
}

.task-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.task-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: white;
  border-radius: var(--radius);
  border: 1px solid var(--border-light);
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
}

.task-item:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
  border-color: var(--primary-light);
}

.task-details {
  flex: 1;
}

.task-name {
  font-weight: 500;
  color: var(--text-dark);
  display: block;
  margin-bottom: 0.25rem;
}

.task-dates {
  font-size: var(--font-xs);
  color: var(--text-medium);
  display: block;
  margin-bottom: 0.75rem;
}

.task-progress {
  height: 6px;
  background-color: #e9ecef;
  border-radius: 3px;
  position: relative;
  width: 100%;
  max-width: 200px;
}

.progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 3px;
  transition: width 0.3s ease;
}

.task-progress span {
  position: absolute;
  right: -30px;
  top: -7px;
  font-size: var(--font-xs);
  color: var(--text-medium);
  font-weight: 500;
}

.btn-icon {
  background: none;
  border: none;
  color: var(--text-light);
  cursor: pointer;
  font-size: var(--font-base);
  padding: 0.4rem;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.btn-icon:hover {
  color: #dc3545;
  background-color: #f8f9fa;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 0.5rem;
  }

  .modal-actions {
    flex-direction: column-reverse;
    gap: 0.75rem;
  }

  .btn {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .create-project-modal {
    width: 95%;
    max-height: 95vh;
  }

  .project-form {
    padding: 1rem;
  }

  .modal-header {
    padding: 1rem;
  }

  .modal-header h2 {
    font-size: var(--font-lg);
  }
}
