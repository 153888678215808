:root {
  --primary-color: #558392;
  --primary-light: rgba(85, 131, 146, 0.1);
  --accent-color: #ff9800;
  --accent-hover: #ffb74d;
  --text-dark: #2c3e50;
  --text-medium: #546e7a;
  --text-light: #ffffff;
  --bg-light: #ffffff;
  --bg-medium: #f5f7f9;
  --shadow-soft: 0 5px 15px rgba(0, 0, 0, 0.05);
  --shadow-medium: 0 8px 30px rgba(0, 0, 0, 0.12);
  --border-radius-sm: 8px;
  --border-radius-md: 12px;
  --border-radius-lg: 20px;
  --transition-fast: 0.2s ease;
  --transition-medium: 0.3s ease;
  --font-family: 'Roboto', sans-serif;
}

/* First, let's reset the modal-overlay for better visibility */
.modal-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 9999 !important; /* Increased z-index to ensure it's above everything */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  transition:
    background-color 0.4s ease,
    backdrop-filter 0.4s ease;
  animation: fadeIn 0.4s forwards;
}

/* Increase background darkness and blur for better contrast */
@keyframes fadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
  to {
    background-color: rgba(0, 0, 0, 0.9); /* Increased to 90% opacity */
    backdrop-filter: blur(8px); /* Stronger blur effect */
  }
}

/* Remove the fadeIn animation and set values directly */
.modal-content {
  background: #ffffff;
  border-radius: var(--border-radius-md);
  padding: 30px;
  width: 90%;
  max-width: 520px; /* Slightly larger */
  max-height: 90vh;
  overflow-y: auto;

  transform: translateY(0) scale(1);
  opacity: 1;
  animation: pulse 1s ease-out;
}

/* Make modal pop with a subtle highlight */
.profile-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--text-dark);
  background: #ffffff;
  border-radius: calc(var(--border-radius-md) - 2px);
}

@keyframes slideUp {
  from {
    transform: translateY(50px) scale(0.95);
    opacity: 0;
  }
  to {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

/* Add closing animations */
.modal-overlay.closing {
  animation: fadeOut 0.3s forwards;
}

.modal-overlay.closing .modal-content {
  animation: slideDown 0.3s forwards;
}

/* Update closing animation to match new opacity */
@keyframes fadeOut {
  from {
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(8px);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  to {
    transform: translateY(30px) scale(0.95);
    opacity: 0;
  }
}

/* Make the modal title stand out more */
.modal-title {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--text-dark);
  margin-bottom: 10px;
  text-align: center;
  position: relative;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.modal-title:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: var(--accent-color);
  border-radius: 1px;
}

.profile-preview {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

/* Add a subtle glow to the profile image */
.profile-preview-image {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  object-fit: cover;
  border: 3px solid var(--primary-light);
  box-shadow:
    var(--shadow-soft),
    0 0 15px rgba(85, 131, 146, 0.2); /* Subtle glow in primary color */
  transition: transform var(--transition-medium);
}

.profile-preview-image:hover {
  transform: scale(1.05);
}

.profile-preview-placeholder {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-light);
  color: var(--primary-color);
  font-size: 3rem;
  font-weight: bold;
  border: 3px solid var(--primary-light);
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--text-medium);
}

.form-group input[type='text'] {
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  border-radius: var(--border-radius-sm);
  font-size: 1rem;
  transition: var(--transition-fast);
  font-family: var(--font-family);
}

.form-group input[type='text']:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(85, 131, 146, 0.2);
}

.file-upload {
  margin-top: 5px;
}

.file-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-input-button {
  background-color: var(--primary-color, #4a90e2);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.file-input-button:hover {
  background-color: var(--primary-dark, #3a7bc8);
}

.file-input-button:active {
  transform: translateY(1px);
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-name {
  margin-left: 8px;
  font-size: 14px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.upload-progress {
  margin-top: 5px;
}

.progress-bar {
  height: 6px;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 8px;
}

.progress-bar-inner {
  height: 100%;
  width: 50%;
  background-color: var(--accent-color);
  border-radius: 1px;
  animation: progress 1.5s infinite;
}

@keyframes progress {
  0% {
    width: 0%;
    margin-left: 0%;
  }
  50% {
    width: 50%;
    margin-left: 25%;
  }
  100% {
    width: 0%;
    margin-left: 100%;
  }
}

.form-actions {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.submit-btn,
.cancel-btn {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: var(--border-radius-sm);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition:
    transform var(--transition-fast),
    background-color var(--transition-fast);
}

.submit-btn {
  background-color: var(--accent-color);
  color: white;
}

.submit-btn:hover {
  background-color: var(--accent-hover);
  transform: translateY(-2px);
}

.cancel-btn {
  background-color: #e0e0e0;
  color: var(--text-medium);
}

.cancel-btn:hover {
  background-color: #d0d0d0;
  transform: translateY(-2px);
}

.submit-btn:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  transform: none;
}

@media (max-width: 600px) {
  .modal-content {
    padding: 20px;
  }

  .form-actions {
    flex-direction: column;
  }

  .profile-preview-image,
  .profile-preview-placeholder {
    width: 100px;
    height: 100px;
  }
}

/* Add a pulsing effect to draw attention */
@keyframes pulse {
  0% {
    box-shadow:
      0 0 0 3px var(--accent-color),
      0 0 0 6px rgba(255, 152, 0, 0.3),
      0 25px 50px rgba(0, 0, 0, 0.5);
    transform: scale(0.95);
  }
  50% {
    box-shadow:
      0 0 0 5px var(--accent-color),
      0 0 0 10px rgba(255, 152, 0, 0.4),
      0 25px 50px rgba(0, 0, 0, 0.5);
    transform: scale(1.02);
  }
  100% {
    box-shadow:
      0 0 0 3px var(--accent-color),
      0 0 0 6px rgba(255, 152, 0, 0.3),
      0 25px 50px rgba(0, 0, 0, 0.5);
    transform: scale(1);
  }
}

/* Use a completely different class name to avoid conflicts */

/* Create new profile-specific modal classes */
.profile-edit-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 9999 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(4px);
}

.profile-edit-content {
  background: #ffffff;
  border-radius: var(--border-radius-md);
  padding: 30px;
  width: 90%;
  max-width: 520px;
  max-height: 90vh;
  overflow-y: auto;
  border: 2px solid var(--accent-color); /* Very thick visible border */
  box-shadow:
    0 0 0 10px rgba(255, 255, 255, 0.1),
    /* White outer glow */ 0 20px 50px rgba(0, 0, 0, 0.5);
}

.profile-edit-content.create-company-container {
  background: #ffffff;
  border-radius: 8px;
  padding: 30px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;
}

.profile-edit-content .profile-preview {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.profile-edit-content .form-control {
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.2s;
  width: 100%;
}

.profile-edit-content .form-control:focus {
  border-color: #558392;
  outline: none;
  box-shadow: 0 0 0 2px rgba(85, 131, 146, 0.2);
}

.profile-edit-content .form-control.is-invalid {
  border-color: #dc3545;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.profile-edit-content .alert {
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.profile-edit-content .alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.profile-edit-content .file-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-edit-content .file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.profile-edit-content .form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.profile-edit-content .btn {
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.profile-edit-content .btn-outline-secondary {
  border: 1px solid #6c757d;
  color: #6c757d;
  background: transparent;
}

.profile-edit-content .btn-outline-secondary:hover {
  background-color: #f8f9fa;
}

.profile-edit-content .btn-primary {
  background-color: #558392;
  color: white;
  border: none;
}

.profile-edit-content .btn-primary:hover {
  background-color: #47707e;
}

.profile-edit-content .btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.company-field-info {
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f8f8f8;
}

.current-company {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.company-name {
  font-weight: 500;
  color: #333;
}

.view-company-link,
.create-company-link {
  color: #558392;
  text-decoration: none;
  font-size: 0.9rem;
  display: inline-block;
  margin-top: 4px;
}

.view-company-link:hover,
.create-company-link:hover {
  text-decoration: underline;
}

.no-company-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #666;
}

.help-text {
  display: block;
  margin-top: 8px;
  font-size: 0.8rem;
  color: #666;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.profile-edit-overlay.closing {
  animation: fadeOut 0.2s forwards; /* Reduced from 0.3s to 0.2s */
  pointer-events: none; /* Prevent clicks during fade-out */
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Add this to fix any stuck backdrop issues */
.profile-edit-overlay.closing .profile-edit-content {
  animation: modalZoomOut 0.2s forwards;
}

@keyframes modalZoomOut {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.95);
    opacity: 0;
  }
}
